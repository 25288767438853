<style lang="scss">
.tabla-container{
  width: 100%;
  height: 85px;
  overflow: auto;
}
.v-text-field--filled.v-input--dense.v-text-field--single-line >
.v-input__control > .v-input__slot, .v-text-field--filled.v-input--dense.v-text-field--outlined >
.v-input__control > .v-input__slot,
.v-text-field--filled.v-input--dense.v-text-field--outlined.v-text-field--filled >
.v-input__control > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--single-line >
.v-input__control > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined >
.v-input__control > .v-input__slot,
.v-text-field--full-width.v-input--dense.v-text-field--outlined.v-text-field--filled >
.v-input__control > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--single-line >
.v-input__control > .v-input__slot, .v-text-field--outlined.v-input--dense.v-text-field--outlined >
.v-input__control > .v-input__slot,
.v-text-field--outlined.v-input--dense.v-text-field--outlined.v-text-field--filled >
.v-input__control > .v-input__slot {
    min-height: 37px !important;
}
.v-text-field input {
    font-size: 14px !important;
}
.v-select__selections {
    font-size: 14px !important;
}
.v-label.theme--light {
    font-size: 14px !important;
    color: #000 !important;
    font-weight: 400 !important;
}
.border-bottom{
  border-bottom: 1px solid #bdbdbd;
}
.pb-10{
  padding-bottom: 10px;
}
.fs20{
  font-size: 20px;
}
.w-100{width: 100% !important;}
.justify-content-space-around{justify-content: space-around;}

  #app .v-application .ensayos__creados.v-data-table td {
    padding: 16px 20px !important;
  }
  .v-application--is-ltr .v-data-footer__select .v-select {
    margin: 2px 0 2px 34px;
  }
</style>
<style  scoped>
  .v-btn:not(.v-btn--round).v-size--small {
      padding: 19px 12px !important;
  }
  .cont__umbrales{
    text-align: justify;
    border: 1px solid #adadad;
    border-radius: 8px;
    max-height: 80px;
    overflow: auto;
  }
  .cont__umbrales > span{
      color:#666666 !important;
      font-size: 12px !important;
  }
  .v-tooltip{
    z-index: 100;
  }
</style>
<template>
<!--eslint-disable-->
<div class="c-add-new-laboratory-test">
  <v-form class="o-umbrales" ref="umbralesform" v-model="valid" lazy-validation >
    <v-row class="mt-3">
      <v-col class="pl-0 pb-0 mt-5 text-left c-add-new-laboratory-test__step" cols="12">
        <div class="d-flex align-items-center c-add-new-laboratory-test__step-number">
          <svg class="mr-3" id="Numeral"  width="20" height="20" viewBox="0 0 20 20">
            <g id="Elipse_146" data-name="Elipse 146" fill="#fff" stroke="#057aff" stroke-width="1.5">
              <circle cx="10" cy="10" r="10" stroke="none"/>
              <circle cx="10" cy="10" r="9.25" fill="none"/>
            </g>
            <text id="_1" data-name="1" transform="translate(6.171 14.139)" fill="#1a1a1a" font-size="12" font-family="OpenSans-Regular, Open Sans"><tspan x="0" y="0">4</tspan></text>
          </svg>
          <p class="fs16 bold mb-0 c-add-new-laboratory-test__step-title"> {{ $t('ensayos.definir') }} </p>
        </div>
        <p class="ml-8 c-add-new-laboratory-test__step-subtitle text-left fs14 mb-0">{{ $t('ensayos.selectumbrales') }}</p>
      </v-col>
      
      <div v-if="form_data.steriplex === false">
        <div class="ml-8">
          <div v-if="matrizunidad.ali == 1">
                <div class="pb-4" v-if="activealimento ">
                  <v-row class="pt-5">
                    <v-col class=" text-left py-0" cols="12" md="5">
                      <span class="fs18 bold">{{ $t('ensayos.alimento') }}</span>
                    </v-col>
                    
                  </v-row>

                    <v-row>
                      <v-col cols="12" md="6" >
                        <template>
                          <div>
                            <treeselect
                            :limit="5"
                            :multiple="true"
                            :options="alimentoItems"
                            :value-consists-of="valueConsistsOf"
                            v-model="alimentoItem.rsa_id"
                            :max-height="350"
                            :placeholder="$t('ensayos.alimento')"
                            class="select"
                            :no-children-text="$t('ensayos.no_sub_options')"
                            :no-results-text="$t('ensayos.no_results')"
                            dense
                            >
                              <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                {{ $t(`${node.label}`) }}
                              </label>
                              <div slot="value-label" slot-scope="{ node }">
                                  {{ node.raw.customLabel }}
                              </div>
                            </treeselect>
                          </div>
                        </template>
                      </v-col>
                      <v-col cols="12" md="6" class="text-left">
                        <v-btn
                          class="c-btn"
                          color="#007AFF"
                          outlined
                          v-on:click="configumbrales(12)"
                        >
                          {{$t('ensayos.Assignunitsofmeasure')}}
                        </v-btn>
                        <v-btn
                          class="ml-5 c-btn"
                          color="#007AFF"
                          text
                          v-if="false"
                        >
                          <svg class="mr-2" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                            <g id="plus-circle" transform="translate(-0.869 -0.869)">
                              <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                              <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                              <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                            </g>
                          </svg>
                          {{$t('ensayos.Addconditional')}}
                        </v-btn>

                      </v-col>
                      
                      <v-col class=" pl-0  dimg" cols="12" md="1" v-if="false" >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-on:click="additemumbral()"
                            >
                              <v-icon
                                color="secondary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >mdi-plus-circle-outline</v-icon>
                            </a>
                          </template>
                          <span>{{ $t('ensayos.Addthresholds') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <!-- armamos los irtem de lo que vamos generando -->
                    <v-row
                    v-for="(item, index) in umbralAlimento"
                    :key="'AL'+index"
                    class="mb-4"
                    >
                      <v-col class="text-left" cols="12" md="6" >
                        <div class="content-unidades content-umbrales">
                          <!--<v-chip
                            label
                            v-for="chips in item.items"
                            :key="chips.idarbol"
                          >
                          {{ chips.label }}
                          </v-chip>-->
                          <v-tooltip right v-for="chips in item.items" :key="chips.idarbol">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                label
                                v-bind="attrs"
                                v-on="on"
                              >
                              {{ chips.label }}
                              </v-chip>
                            </template>
                            <span>{{ chips.customLabel }}</span>
                        </v-tooltip>
                        </div>
                      </v-col>
                      <v-col class="text-left" cols="12" md="6" >
                        <v-btn
                          class="c-btn"
                          color="#007AFF"
                          outlined
                          v-on:click="showiteminfo(item.parametrosubral)"
                        >
                          {{$t('ensayos.viewunitsofmeasure')}}
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="ml-4"
                          v-on:click="deleteUmbral(item,12)"
                        >
                          <svg width="19" height="21" viewBox="0 0 15.4 17">
                            <g id="trash-2" transform="translate(-2.5 -1.5)">
                              <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                          </svg>
                        </v-btn>
                        <!--<div class="d-flex justify-content-space-between">
                           <div class="tabla-container">
                            <table class="w-100">
                              <tr v-if="index==0">
                                <th>{{$t('ensayos.thparam')}}</th>
                                <th>{{$t('ensayos.thumbral')}}</th>
                                <th>{{$t('ensayos.thunidad')}}</th>
                              </tr>
                              <tr
                                v-for="param in item.parametrosubral"
                                :key="param.id"
                              >
                              <td class="w-33 text-center">{{ param.nombre }}</td> 
                              <td class="w-33 text-center">{{ param.umbral }}</td> 
                              <td class="w-33 text-center">{{ param.unidades }}</td> 
                              </tr>
                            </table>
                          </div> 
                        </div>-->
                      </v-col>
                    </v-row>
                    <!-- termina los row que vamos agregando -->
                </div>
                <div class="pb-4" v-if="activesuperficie">
                    <v-row class="pt-5">
                    <v-col class="text-left py-0" cols="12" md="5">
                      <span class="fs18 bold">{{ $t('ensayos.superficies') }}</span>
                  </v-col>
                </v-row>
                  <v-row>
                      <v-col cols="12" md="6">
                          <template>
                            <div>
                              <treeselect
                              :limit="5"
                              v-model="superficieItem.rsa_id"
                              :multiple="true"
                              :options="superficieItems"
                              :value-consists-of="valueConsistsOf"
                              :max-height="350"
                              :placeholder="$t('ensayos.superficies')"
                              class="select"
                              :no-children-text="$t('ensayos.no_sub_options')"
                              :no-results-text="$t('ensayos.no_results')"
                              >
                                <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                  {{ $t(`${node.label}`) }}
                                </label>
                              <div slot="value-label" slot-scope="{ node }">
                                {{ node.raw.customLabel }}
                              </div>
                              </treeselect>
                            </div>
                          </template>
                      </v-col>
                      <v-col cols="12" md="6"  class="text-left">
                          <v-btn
                            class="c-btn"
                            color="#007AFF"
                            outlined
                            v-on:click="configumbrales(9)"
                          >
                            {{$t('ensayos.Assignunitsofmeasure')}}
                          </v-btn>
                          <v-btn
                          class="ml-5 c-btn"
                          color="#007AFF"
                          text
                          v-if="false"
                          >
                            <svg class="mr-2" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                              <g id="plus-circle" transform="translate(-0.869 -0.869)">
                                <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                              </g>
                            </svg>
                            {{$t('ensayos.Addconditional')}}
                          </v-btn>
                      </v-col>
                      <v-col cols="12" md="3"  v-if="false">
                        <v-text-field
                        name="unidades"
                        autocomplete="off"
                        v-model="superficieItem.unidades"
                        :label="$t('ensayos.unidades')"
                        outlined
                        dense
                        solo
                        class="select"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <!-- armamos los irtem de lo que vamos generando -->
                    <v-row
                    v-for="(item, index) in umbralSuperficie"
                    :key="'SU'+index">
                      <v-col class="text-left" cols="12" md="6" >
                        <div class="content-unidades content-umbrales">
                          <!--<v-chip
                          label
                          v-for="chips in item.items"
                          :key="chips.idarbol"
                        >{{ chips.label }}</v-chip>-->
                        <v-tooltip right v-for="chips in item.items" :key="chips.idarbol">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                label
                                v-bind="attrs"
                                v-on="on"
                              >
                              {{ chips.label }}
                              </v-chip>
                            </template>
                            <span>{{ chips.customLabel }}</span>
                        </v-tooltip>
                        </div>
                      </v-col>
                      <v-col class="text-left" cols="12" md="6" >
                        <v-btn
                          class="c-btn"
                          color="#007AFF"
                          outlined
                          v-on:click="showiteminfo(item.parametrosubral)"
                        >
                          {{$t('ensayos.viewunitsofmeasure')}}
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="ml-4"
                          v-on:click="deleteUmbral(item,9)"
                        >
                          <svg width="19" height="21" viewBox="0 0 15.4 17">
                            <g id="trash-2" transform="translate(-2.5 -1.5)">
                              <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                          </svg>
                        </v-btn>
                      </v-col>
                      
                    </v-row>
                    <!-- termina los row que vamos agregando -->
                </div>
                <div class="pb-4" v-if="activemanipulador">
                    <v-row class="pt-5">
                    <v-col class="text-left py-0" cols="12" md="5">
                      <span class="fs18 bold">{{ $t('ensayos.manipuladores') }}</span>
                  </v-col>
                </v-row>
                  <v-row>
                      <!-- nuevo -->
                        <v-col cols="12" md="6" >
                            <template>
                              <div>
                                <treeselect
                                :limit="5"
                                v-model="manipuladoresItem.rsa_id"
                                :multiple="true"
                                :options="manipuladoresItems"
                                :value-consists-of="valueConsistsOf"
                                :max-height="350"
                                :placeholder="$t('ensayos.manipuladores')"
                                class="select"
                                :no-children-text="$t('ensayos.no_sub_options')"
                                :no-results-text="$t('ensayos.no_results')"
                                >
                                  <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                    {{ $t(`${node.label}`) }}
                                  </label>
                                </treeselect>
                              </div>
                            </template>
                        </v-col>
                        <v-col cols="12" md="6" class="text-left">
                            <v-btn
                              class="c-btn"
                                color="#007AFF"
                                outlined
                              v-on:click="configumbrales(8)"
                            >
                              {{$t('ensayos.Assignunitsofmeasure')}}
                            </v-btn>
                            <v-btn
                              class="ml-5 c-btn"
                              color="#007AFF"
                              text
                              v-if="false"
                            >
                              <svg class="mr-2" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <g id="plus-circle" transform="translate(-0.869 -0.869)">
                                  <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </g>
                              </svg>
                              {{$t('ensayos.Addconditional')}}
                            </v-btn>
                        </v-col>
                        <v-col class=" pl-0 dimg" cols="12" md="1" v-if="false">
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                              v-on:click="additemumbralManipuladores()"
                            >
                              <v-icon
                                color="secondary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >mdi-plus-circle-outline</v-icon>
                            </a>
                          </template>
                          <span>{{ $t('ensayos.Addthresholds') }}</span>
                        </v-tooltip>
                        </v-col>
                    </v-row>
                    <!-- armamos los irtem de lo que vamos generando -->
                    <v-row
                    v-for="(item, index) in umbralManipuladores"
                    :key="'MA'+index">
                      <v-col class="text-left" cols="12" md="6" >
                        <div class="content-unidades content-umbrales">
                          <!--<v-chip
                          label
                          v-for="chips in item.items"
                          :key="chips.idarbol"
                        >{{ chips.label }}</v-chip>-->
                        <v-tooltip right v-for="chips in item.items" :key="chips.idarbol">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                label
                                v-bind="attrs"
                                v-on="on"
                              >
                              {{ chips.label }}
                              </v-chip>
                            </template>
                            <span>{{ chips.customLabel }}</span>
                        </v-tooltip>
                        </div>
                      </v-col>
                      <v-col class="text-left" cols="12" md="6" >
                        <v-btn
                          class="c-btn"
                          color="#007AFF"
                          outlined
                          v-on:click="showiteminfo(item.parametrosubral)"
                        >
                          {{$t('ensayos.viewunitsofmeasure')}}
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="ml-4"
                          v-on:click="deleteUmbral(item,8)"
                        >
                          <svg width="19" height="21" viewBox="0 0 15.4 17">
                            <g id="trash-2" transform="translate(-2.5 -1.5)">
                              <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                          </svg>
                        </v-btn>
                        
                      </v-col>
                      
                    </v-row>
                    <!-- termina los row que vamos agregando -->
                </div>
                <div class="pb-4" v-if="activeaguas">
                    <v-row class="pt-5">
                    <v-col class="text-left py-0" cols="12" md="5">
                      <span class="fs18 bold">{{$t('ensayos.aguas')}}</span>
                    </v-col>
                  </v-row>
                    <v-row >
                        <v-col cols="12" md="6" >
                            <template>
                              <div>
                                <treeselect
                                :limit="5"
                                v-model="aguasItem.rsa_id"
                                :multiple="true"
                                :options="aguasItems"
                                :value-consists-of="valueConsistsOf"
                                :max-height="350"
                                :placeholder="$t('ensayos.aguas')"
                                class="select"
                                :no-children-text="$t('ensayos.no_sub_options')"
                                :no-results-text="$t('ensayos.no_results')"
                                >
                                  <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                    {{ $t(`${node.label}`) }}
                                  </label>
                                <div slot="value-label" slot-scope="{ node }">
                                  {{ node.raw.customLabel }}
                                </div>
                                </treeselect>
                              </div>
                            </template>
                        </v-col>
                        <v-col cols="12" md="6" class="text-left">
                            
                            <v-btn
                              class="c-btn"
                                color="#007AFF"
                                outlined
                              v-on:click="configumbrales(7)"
                            >
                              {{$t('ensayos.Assignunitsofmeasure')}}
                            </v-btn>
                            <v-btn
                              class="ml-5 c-btn"
                              color="#007AFF"
                              text
                              v-if="false"
                            >
                              <svg class="mr-2" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <g id="plus-circle" transform="translate(-0.869 -0.869)">
                                  <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </g>
                              </svg>
                              {{$t('ensayos.Addconditional')}}
                            </v-btn>
                        </v-col>
                        
                      <v-col class=" pl-0  dimg" cols="12" md="1" v-if="false">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                                v-on:click="additemumbralAguas()"
                            >
                              <v-icon
                                color="secondary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >mdi-plus-circle-outline</v-icon>
                            </a>
                          </template>
                          <span>{{ $t('ensayos.Addthresholds') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                    <!-- armamos los irtem de lo que vamos generando -->
                    <v-row
                      v-for="(item, index) in umbralAguas"
                      :key="'AL'+index">
                      <v-col class="text-left" cols="12" md="6" >
                        <div class="content-unidades content-umbrales">
                          <!--<v-chip
                          label
                          v-for="chips in item.items"
                          :key="chips.idarbol"
                        >{{ chips.label }}</v-chip>-->
                        <v-tooltip right v-for="chips in item.items" :key="chips.idarbol">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                label
                                v-bind="attrs"
                                v-on="on"
                              >
                              {{ chips.label }}
                              </v-chip>
                            </template>
                            <span>{{ chips.customLabel }}</span>
                        </v-tooltip>
                        </div>
                        
                      </v-col>
                      <v-col class="text-left" cols="12" md="6" >
                        <v-btn
                          class="c-btn"
                          color="#007AFF"
                          outlined
                          v-on:click="showiteminfo(item.parametrosubral)"
                        >
                          {{$t('ensayos.viewunitsofmeasure')}}
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="ml-4"
                          v-on:click="deleteUmbral(item,7)"
                        >
                          <svg width="19" height="21" viewBox="0 0 15.4 17">
                            <g id="trash-2" transform="translate(-2.5 -1.5)">
                              <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                          </svg>
                        </v-btn>
                        
                      </v-col>
                      
                    </v-row>
                    <!-- termina los row que vamos agregando -->
                </div>
                <div class="pb-4" v-if="activeambientes">
                    <v-row class="pt-5">
                    <v-col class="text-left py-0" cols="12" md="5">
                      <span class="fs18 bold">{{$t('ensayos.ambientes')}}</span>
                    </v-col>
                    
                  </v-row>
                    <v-row>
                        <v-col cols="12" md="6"  >
                            <template>
                              <div>
                                <treeselect
                                :limit="5"
                                v-model="ambientesItem.rsa_id"
                                :multiple="true"
                                :options="ambientesItems"
                                :value-consists-of="valueConsistsOf"
                                :max-height="350"
                                :placeholder="$t('ensayos.ambientes')"
                                class="select"
                                :no-children-text="$t('ensayos.no_sub_options')"
                                :no-results-text="$t('ensayos.no_results')"
                                >
                                  <label slot="option-label" slot-scope="{ node, shouldShowCount, count, labelClassName, countClassName }" :class="labelClassName">
                                    {{ $t(`${node.label}`) }}
                                  </label>
                                <!--<div slot="value-label" slot-scope="{ node }">
                                  {{ node.raw.customLabel }}
                                </div>-->
                                </treeselect>
                              </div>
                            </template>
                        </v-col>
                        <v-col cols="12" md="6" class="text-left">
                            
                            <v-btn
                              class="c-btn"
                                color="#007AFF"
                                outlined
                              v-on:click="configumbrales(6)"
                            >
                              {{$t('ensayos.Assignunitsofmeasure')}}
                            </v-btn>
                            <v-btn
                              class="ml-5 c-btn"
                              color="#007AFF"
                              text
                              v-if="false"
                            >
                              <svg class="mr-2" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <g id="plus-circle" transform="translate(-0.869 -0.869)">
                                  <circle id="Elipse_200" data-name="Elipse 200" cx="8" cy="8" r="8" transform="translate(1.619 1.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <line id="Línea_419" data-name="Línea 419" y2="6" transform="translate(9.619 6.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                  <line id="Línea_420" data-name="Línea 420" x2="6" transform="translate(6.619 9.619)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                </g>
                              </svg>
                              {{$t('ensayos.Addconditional')}}
                            </v-btn>
                        </v-col>
                        
                        <v-col class=" pl-0 dimg" cols="12" md="1"  v-if="false">
                          <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <a
                                v-on:click="additemumbralAmbientes()"
                            >
                              <v-icon
                                color="secondary"
                                dark
                                v-bind="attrs"
                                v-on="on"
                              >mdi-plus-circle-outline</v-icon>
                            </a>
                          </template>
                          <span>{{ $t('ensayos.Addthresholds') }}</span>
                        </v-tooltip>
                        </v-col>
                    </v-row>
                  <!-- armamos los irtem de lo que vamos generando -->
                  <v-row
                    v-for="(item, index) in umbralAmbientes"
                    :key="'AL'+index">
                    <v-col class="text-left" cols="12" md="6" >
                        <div class="content-unidades content-umbrales">
                          <!--<v-chip
                          label
                          v-for="chips in item.items"
                          :key="chips.idarbol"
                        >{{ chips.label }}</v-chip>-->
                        <v-tooltip right v-for="chips in item.items" :key="chips.idarbol">
                            <template v-slot:activator="{ on, attrs }">
                              <v-chip
                                label
                                v-bind="attrs"
                                v-on="on"
                              >
                              {{ chips.label }}
                              </v-chip>
                            </template>
                            <span>{{ chips.customLabel }}</span>
                        </v-tooltip>
                        </div>
                        
                      </v-col>
                      <v-col class="text-left" cols="12" md="6" >
                        <v-btn
                          class="c-btn"
                          color="#007AFF"
                          outlined
                          v-on:click="showiteminfo(item.parametrosubral)"
                        >
                          {{$t('ensayos.viewunitsofmeasure')}}
                        </v-btn>
                        <v-btn
                          icon
                          small
                          class="ml-4"
                          v-on:click="deleteUmbral(item,6)"
                        >
                          <svg width="19" height="21" viewBox="0 0 15.4 17">
                            <g id="trash-2" transform="translate(-2.5 -1.5)">
                              <path id="Trazado_40962" data-name="Trazado 40962" d="M3,6H17.4" transform="translate(0 -0.8)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <path id="Trazado_40963" data-name="Trazado 40963" d="M16.221,5.2V16.4a1.6,1.6,0,0,1-1.6,1.6H6.6A1.6,1.6,0,0,1,5,16.4V5.2m2.4,0V3.6A1.6,1.6,0,0,1,9.008,2h3.206a1.6,1.6,0,0,1,1.6,1.6V5.2" transform="translate(-0.411)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_368" data-name="Línea 368" y2="5" transform="translate(8.917 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                              <line id="Línea_369" data-name="Línea 369" y2="5" transform="translate(11.876 9.08)" fill="none" stroke="#007aff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                          </svg>
                        </v-btn>
                        
                      </v-col>
                    
                    
                  </v-row>
                  <!-- termina los row que vamos agregando -->
                </div>
            </div>
          </div>
          <!-- modal configuracion de umbrales -->
          <v-row justify="center">
            <v-dialog
              v-model="dialogconfig"
              persistent
              max-width="650px"
            >
              <v-card>
                <v-card-title>
                  <span class="ml-1 fs20 bold">{{$t('ensayos.Assignunitsofmeasure')}}</span>
                </v-card-title>
                <div class="c-units__modal">
                  <v-simple-table
                    fixed-header
                    height="200px"
                    dense
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="c-units__th text-left">
                            {{$t('ensayos.thparam')}}
                          </th>
                          <th class="c-units__th text-left" width="150">
                            {{$t('ensayos.thumbral')}}
                          </th>
                          <th class="c-units__th text-left" width="150">
                            {{$t('ensayos.thunidad')}}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                        v-for="(item, index) in umbralParametro" :key="'item'+index"
                        >
                          <td class="c-units__td text-left">
                            {{item.nombre}}
                          </td>
                          <td class="c-units__td text-left">
                            <div class="c-units__div">
                              <v-text-field
                              label="0"
                              v-model="item.umbral"
                              item-value="umbral"
                              solo
                              min="0"
                              type="number"
                              :disabled="form_data.data_ensayo.tipoRes==2||form_data.data_ensayo.tipoRes==4"
                              outlined
                              dense
                              class="select text-center"
                              ></v-text-field>
                            </div>
                          </td>
                          <td class="c-units__td text-left">
                            <div class="c-units__div3">
                              <v-text-field
                                solo
                                outlined
                                v-model="item.unidades"
                                dense
                                class="select text-center"
                              ></v-text-field>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  
                </div>
                <v-card-actions class="p-actions-groupBtn">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="mr-3 c-btn"
                    color="#007AFF"
                    outlined
                    @click="dialogconfig = false"
                  >
                    {{$t('analisis.cancelar')}}
                  </v-btn>
                  <v-btn
                    class="mr-3 c-btn c-btn--blue bold"
                    outlined
                    @click="guardaUmbralparametro()"
                  >
                    {{$t('solicitudes.guardar')}}
                  </v-btn>
                  
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
          <!-- fin modal -->
            <modalunidades  ref="modalunidades"></modalunidades>
          <!-- 
           -->
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" md="12" >
            <p class="font-weight-black">PARAMETROS Y NIVEL DE PELIGROSIDAD</p>
          </v-col>
          <v-col cols="3" md="3"  align="center"
                v-for="(item) in form_data.parametrosSelected"
                :key="item.id">
                  <v-select
                    :items="data_ensayo.nivelPeligro"
                    :label="item.nombre"
                    v-model="item.peligrosidadID"
                    outlined
                    item-text="descripcion"
                    item-value="id"
                  ></v-select>
                </v-col>
        </v-row>
      </div>
    </v-row>
  </v-form>
</div>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations, mapActions } from 'vuex';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import i18n from '../../plugins/il8n';
import modalunidades from './modalunidades.vue';

export default {
  components: {
    Treeselect,
    modalunidades,
    },
  name: 'Umbrales',
  data: () => ({
    item_node: null,
    dialogInfoUnidades: false,
    dialogconfig: false,
    defaultumbral:0,
    valid: false,
    umbralAlimento: [],
    umbralSuperficie: [],
    umbralManipuladores :[],
    umbralAguas :[],
    umbralAmbientes:[],
    alimentoItem:{
      rsa_id:[],
      // umbral:0,
      umbral:null,
      unidades:'',
    },
    superficieItem:{
      rsa_id:[],
      // umbral:0,
      umbral:null,
      unidades:'',
    },
    manipuladoresItem:{
      rsa_id:[],
      // umbral:0,
      umbral:null,
      unidades:'',
    },
    aguasItem:{
      rsa_id:[],
      // umbral:0,
      umbral:null,
      unidades:'',
    },
    ambientesItem:{
      rsa_id:[],
      // umbral:0,
      umbral:null,
      unidades:'',
    },
    form_dataens: {
      data_ensayo: {
        alimentosUmbral: [],
      },
    },
    alimentosarray: [
      {
        id: 0,
        rsa_id: [],
        // umbral: '',
        umbral:null,
      }
    ],
    superficiesarray: [
      {
        id: 0,
        rsa_id: [],
        // umbral: '',
        umbral:null,
      }
    ],
    manupuladoresarray: [
      {
        id: 0,
        rsa_id: [],
        // umbral: '',
        umbral:null,
      }
    ],
    aguasarray: [
      {
        id: 0,
        rsa_id: [],
        // umbral: '',
        umbral:null,
      }
    ],
    ambientesarray: [
      {
        id: 0,
        rsa_id: [],
        // umbral: '',
        umbral:null,
      }
    ],
    selectedFruits: [],
    valueConsistsOf: 'LEAF_PRIORITY',
    rules: {
      tipoMuestra: [
        (v) => (v.length > 0) || i18n.t('ensayos.rules_txt15'),
      ],
    },
    alimentoItems:[],
    superficieItems:[],
    manipuladoresItems:[],
    aguasItems:[],
    ambientesItems:[],
    umbralParametro:[],
    matrizConfig: 0,
    umbralparametroSuperficie:[],
    umbralparametroManipulador:[],
    umbralparametroAgua:[],
    umbralparametroAmbiente:[],
    controlUmbral: null,
  }),
  afterCreate() {

  },
  computed: {
    ...mapState('Ensayos', ['form_data','data_ensayo', 'matrizunidad', 'alimentosSelect','superficiesSelect', 'dataControl']),
    activealimento() {
      return this.form_data.matrices_unidades[0].activo;
    },
    activesuperficie() {
      return this.form_data.matrices_unidades[1].activo;
    },
    activemanipulador() {
      return this.form_data.matrices_unidades[2].activo;
    },
    activeaguas() {
      return this.form_data.matrices_unidades[3].activo;
    },
    activeambientes() {
      return this.form_data.matrices_unidades[4].activo;
    },

    listaAlimentos() {
      const listaalim = [];
      return []; //listaalim;

    },

    listaSuperficies(){
        const listasup = [];
        /*this.data_ensayo.umbralSuperficie.forEach(element => {

          const nom = element.zona.nombre;
          const trad =  this.getlang(nom);

          element.customLabel = trad; //element.zona.nombre;
          element.id_original = element.zona.id;
          element.active = true;
          element.id = element.zona.id+'_R';
          element.label = trad; //element.zona.nombre;
          // array_children.push(element)
          let array_children = [];
          element.zona.clasificacion_zonas.forEach(element2 => {
              const nom2 = element2.nombre;
              const trad2 =  this.getlang(nom2);
              // element2.customLabel = element.zona.nombre + ' / ' + element2.nombre;
              element2.customLabel = trad + ' / ' + trad2;
              element2.id = element2.id+'_S_'+element.id_original;
              element2.label = trad2
              array_children.push(element2)
          });

          listasup.push(element)

        });*/
        return listasup;
    },
    listaManipuladores() {
      const listaman = [];

      /*this.data_ensayo.umbralManipuladores.forEach(element => {
          const nom2 = element.estado_condicion.condicion;
          const trad2 =  this.getlang(nom2);

          element.active = true;
          element.id = element.estado_condicion.id+'_R';
          element.label = trad2; // element.estado_condicion.condicion;
          let array_children = [];
          element.children=array_children;
          listaman.push(element)
      });*/
      return listaman;
    },
    listaAguas() {
      const listasup = [];
      /*
      this.data_ensayo.umbralAgua.forEach(element => {
          element.customLabel = element.categoria_matriz.nombre;
          element.active = true;
          element.id = element.categoria_matriz.id+'_R';
          element.label = element.categoria_matriz.nombre;
          let array_children = [];
          element.categoria_matriz.kits.forEach(element2 => {
            element2.customLabel = element.categoria_matriz.nombre + ' / ' + element2.kit;
            element2.id = element2.id+'_S_'+element.id;
            element2.label = element2.kit
            array_children.push(element2)
          });
          element.children=array_children;
          listasup.push(element)
      });*/
      return listasup;
    },
    listaAmbiente() {
      const listaamb = [];
      this.data_ensayo.umbralAmbiente.forEach(element => {
          /*element.customLabel = element.estado_condicion.condicion;*/
          const nom2 = element.estado_condicion.condicion;
          const trad2 =  this.getlang(nom2);

          element.active = true;
          element.id = element.estado_condicion.id+'_R';
          element.label = trad2; // element.estado_condicion.condicion;
          let array_children = [];
          /*element.customLabel = element.estado_condicion.condicion + ' / ' + element.children=array_children;*/
          element.children=array_children;
          listaamb.push(element)
      });
      return listaamb;
    },
    likesAllFruit () {
      return this.selectedFruits.length === this.listaAlimentos.length
    },
    likesSomeFruit () {
      return this.selectedFruits.length > 0 && !this.likesAllFruit
    },
    icon () {
      if (this.likesAllFruit) return 'mdi-close-box'
      if (this.likesSomeFruit) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    
  },
  watch: {
    multiple(newValue) {
      if (newValue) {
        this.value = this.value ? [ this.value ] : []
      } else {
        this.value = this.value[0];
      }
    },
  },
  created(){
   
     this.actionRefUmbrales(this);
    const listaalim = [];
    const listaAguas = [];
    //console.log('created alimento',this.data_ensayo.umbralAlimento);
    //genera arbol de alimentos
    this.data_ensayo.umbralAlimento.forEach(element => {
      //asignamos traduccion
      const nom = element.nombre;
      const trad =  this.getlang(nom);
      let niv_1 = element.id;
      element.customLabel = trad; //element.categoria_matriz.nombre;
      element.id_original = element.id;
      element.active = true;
      element.id ='L_'+niv_1 //element.categoria_matriz.id+'_R';
      element.label = trad; //element.categoria_matriz.nombre;
      let array_children = [];
      element.nivel_2.forEach(element2 => {
          let niv_2 = element2.id;
          const nomLabel = element2.nombre;
          let labelKit = this.labelLang(nomLabel);
          element2.labelLang = labelKit;
          element2.customLabel = trad + ' / ' + labelKit;
          element2.id ='L_'+niv_1+'_'+niv_2; //element2.id+'_S_'+element.id_original;
          element2.label = labelKit; //element2.kit

          //recorremos el nivel 3 (subkits)
          let array_children_3 = [];
          element2.nivel3.forEach(element3 => {
            let niv_3 = element3.id;
            element3.labelLang = element3.nombre;
            element3.customLabel = element2.customLabel + ' / ' + element3.nombre;
            element3.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3;//element3.id+'_S_'+element.id_original;
            element3.label = element3.nombre;
            ///recorremos el nivel 4 (categoriniveluno)
            let array_children_4 = [];
            element3.nivel4.forEach(element4 => {
              let niv_4 = element4.id;
              element4.labelLang = element4.nombre;
              element4.customLabel = element3.customLabel + ' / ' + element4.nombre;
              element4.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4;//element4.id+'_S_'+element.id_original;
              element4.label = element4.nombre;
              ///recorremos el nivel 5 (categoriniveldos)
              let array_children_5 = [];
              element4.nivel5.forEach(element5 => {
                let niv_5 = element5.id;
                element5.labelLang = element5.nombre;
                element5.customLabel = element4.customLabel + ' / ' + element5.nombre;
                element5.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5;
                element5.label = element5.nombre;
                ///recorremos el nivel 6 (categoriniveltres)
                let array_children_6 = [];
                element5.nivel6.forEach(element6 => {
                  let niv_6 = element6.id;
                  element6.labelLang = element6.nombre;
                  element6.customLabel = element5.customLabel + ' / ' + element6.nombre;
                  element6.id ='L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5+'_'+niv_6;
                  element6.label = element6.nombre;
                  ///recorremos el nivel 7 (categorinivelcuatro)
                  let array_children_7 = [];
                  element6.nivel7.forEach(element7 => {
                    let niv_7 = element7.id;
                    element7.labelLang = element7.nombre;
                    element7.customLabel = element6.customLabel + ' / ' + element7.nombre;
                    element7.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5+'_'+niv_6+'_'+niv_7;
                    element7.label = element7.nombre;
                    array_children_7.push(element7)
                  })
                  element6.children=array_children_7;
                  array_children_6.push(element6)
                })
                element5.children=array_children_6;
                array_children_5.push(element5)
              })
              element4.children=array_children_5;
              array_children_4.push(element4)
            });
            element3.children=array_children_4;
            array_children_3.push(element3)
          })
          element2.children=array_children_3;
          array_children.push(element2)
      });

      element.children=array_children;
      listaalim.push(element)
    });
    //console.log('created alimento format',listaalim);
    //console.log('created aguas',this.data_ensayo.umbralAgua);
    //genera arbol de superficies
    this.data_ensayo.umbralAgua.forEach(element => {
      //asignamos traduccion
      const nom = element.nombre;
      const trad =  this.getlang(nom);
      let niv_1 = element.id;
      element.customLabel = trad; //element.categoria_matriz.nombre;
      element.id_original = element.id;
      element.active = true;
      element.id ='L_'+niv_1 //element.categoria_matriz.id+'_R';
      element.label = trad; //element.categoria_matriz.nombre;
      let array_children = [];
      element.nivel_2.forEach(element2 => {
        let niv_2 = element2.id;
        const nomLabel = element2.nombre;
        let labelKit = this.labelLang(nomLabel);
        element2.labelLang = labelKit;
        element2.customLabel = trad + ' / ' + labelKit;
        element2.id ='L_'+niv_1+'_'+niv_2; //element2.id+'_S_'+element.id_original;
        element2.label = labelKit; //element2.kit

        //recorremos el nivel 3 (subkits)
        let array_children_3 = [];
        element2.nivel3.forEach(element3 => {
          let niv_3 = element3.id;
          element3.labelLang = element3.nombre;
          element3.customLabel = element2.customLabel + ' / ' + element3.nombre;
          element3.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3;//element3.id+'_S_'+element.id_original;
          element3.label = element3.nombre;
          ///recorremos el nivel 4 (categoriniveluno)
          let array_children_4 = [];
          element3.nivel4.forEach(element4 => {
            let niv_4 = element4.id;
            element4.labelLang = element4.nombre;
            element4.customLabel = element3.customLabel + ' / ' + element4.nombre;
            element4.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4;//element4.id+'_S_'+element.id_original;
            element4.label = element4.nombre;
            ///recorremos el nivel 5 (categoriniveldos)
            let array_children_5 = [];
            element4.nivel5.forEach(element5 => {
              let niv_5 = element5.id;
              element5.labelLang = element5.nombre;
              element5.customLabel = element4.customLabel + ' / ' + element5.nombre;
              element5.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5;
              element5.label = element5.nombre;
              ///recorremos el nivel 6 (categoriniveltres)
              let array_children_6 = [];
              element5.nivel6.forEach(element6 => {
                let niv_6 = element6.id;
                element6.labelLang = element6.nombre;
                element6.customLabel = element5.customLabel + ' / ' + element6.nombre;
                element6.id ='L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5+'_'+niv_6;
                element6.label = element6.nombre;
                ///recorremos el nivel 7 (categorinivelcuatro)
                let array_children_7 = [];
                element6.nivel7.forEach(element7 => {
                  let niv_7 = element7.id;
                  element7.labelLang = element7.nombre;
                  element7.customLabel = element6.customLabel + ' / ' + element7.nombre;
                  element7.id = 'L_'+niv_1+'_'+niv_2+'_'+niv_3+'_'+niv_4+'_'+niv_5+'_'+niv_6+'_'+niv_7;
                  element7.label = element7.nombre;
                  array_children_7.push(element7)
                })
                element6.children=array_children_7;
                array_children_6.push(element6)
              })
              element5.children=array_children_6;
              array_children_5.push(element5)
            })
            element4.children=array_children_5;
            array_children_4.push(element4)
          });
          element3.children=array_children_4;
          array_children_3.push(element3)
        })
        element2.children=array_children_3;
        array_children.push(element2)
      });

      element.children=array_children;
      listaAguas.push(element)
    });
    this.alimentoItems = listaalim;
    this.aguasItems = listaAguas;
    this.superficieItems = this.nivelesSuperficie();
    this.manipuladoresItems = this.nivelesManipuladores();
    this.ambientesItems = this.nivelesAmbientes();
    this.form_data.data_umbrales.alimentosSelect = this.form_data.data_umbrales.alimentosSelect.filter(umbrales=>umbrales.rsa_id.length > 0);
    this.form_data.data_umbrales.superficiesSelect = this.form_data.data_umbrales.superficiesSelect.filter(umbrales=>umbrales.rsa_id.length > 0);
    this.form_data.data_umbrales.manipuldoresSelect = this.form_data.data_umbrales.manipuldoresSelect.filter(umbrales=>umbrales.rsa_id.length > 0);
    this.form_data.data_umbrales.aguasSelect = this.form_data.data_umbrales.aguasSelect.filter(umbrales=>umbrales.rsa_id.length > 0);
    this.form_data.data_umbrales.ambientesSelect = this.form_data.data_umbrales.ambientesSelect.filter(umbrales=>umbrales.rsa_id.length > 0);
    //checa si hay umbrales en caso de edicion para bloquear los checks y nose puedan generar mas umbrale siguales
    this.bloquearUmbrales();
  },

  methods: {
    ...mapMutations('Ensayos', ['setPatSelected', 'setState', 'setRSA', 'pushAlimentosArray', 'addAlimento']),
    ...mapActions('Ensayos', ['actionRangos','actionRefUmbrales']),

    validate() {
      return this.$refs.umbralesform.validate();
    },
    showiteminfo(info) {
      console.log({info:info});
      this.$refs.modalunidades.init( info );
      // this.dialogInfoUnidades = true;
    },
    getlang(lang){
      let idioma = lang;
      switch (lang) {
        case 'Materia Prima':
          idioma = i18n.t('submatriz.MateriaPrima');
          break;
        case 'Producto Terminado':
          idioma = i18n.t('submatriz.ProductoTerminado');
          break;
        case 'Semi Elaborado':
          idioma = i18n.t('submatriz.SemiElaborado');
          break;
        case 'zona 1':
          idioma = i18n.t('submatriz.zona1');
          break;
        case 'zona 2':
          idioma = i18n.t('submatriz.zona2');
          break;
        case 'zona 3':
          idioma = i18n.t('submatriz.zona3');
          break;
        case 'Critica':
          idioma = i18n.t('submatriz.critica');
          break;
        case 'No Critica':
          idioma = i18n.t('submatriz.nocritica');
          break;
        case 'Pre-Sanitización':
          idioma = i18n.t('submatriz.presanitizacion');
          break;
        case 'Post-Sanitización':
          idioma = i18n.t('submatriz.postsanitizacion');
          break;
        case 'En Uso':
          idioma = i18n.t('submatriz.enuso');
          break;
        case 'Con Guantes':
          idioma = i18n.t('submatriz.guante');
          break;
        default:
          idioma = lang;
          break;
      }
      return idioma;
    },
    edit_tecnica_pat(enabled, id_pat) {
      let found = false;
      this.form_data.patogenos_selected.map((v) => {
        if (v.cod_pat === id_pat.cod_pat) { found = true; }
      });
      // //console.log([this.form_data.patogenos_selected , id_pat.cod_pat ] );
      if (found) {
        // this.dialog = enabled;
        this.setPatSelected(id_pat);
      } else if (!found) {
        this.snackbar = true;
      }
    },
    clear() {
      // this.dialog = false;
    },
    additemAlimento() {
      const id = this.form_data.data_umbrales.alimentosSelect.length + 1;
      this.form_data.data_umbrales.alimentosSelect.push({
        id:id,
        rsa_id: [],
        // umbral: 0,
        umbral:null,
      });
      this.alimentosarray = this.form_data.data_umbrales.alimentosSelect;
    },
    deleteitemUmbral(index, matriz) {
      // console.log(index, matriz);
      switch (matriz) {
        case 'A':
          // this.form_data.alimentosSelect.splice(index, 1);
          // this.alimentosarray = this.form_data.alimentosSelect;
          this.form_data.data_umbrales.alimentosSelect.splice(index, 1);
          this.alimentosarray = this.form_data.data_umbrales.alimentosSelect;
          break;
        case 'S':
          // this.form_data.superficiesSelect.splice(index, 1);
          // this.superficiesarray = this.form_data.superficiesSelect;
          this.form_data.data_umbrales.superficiesSelect.splice(index, 1);
          this.superficiesarray = this.form_data.data_umbrales.superficiesSelect;
          break;
        case 'M':
          // this.form_data.manipuldoresSelect.splice(index, 1);
          // this.manupuladoresarray = this.form_data.manipuldoresSelect;
          this.form_data.data_umbrales.manipuldoresSelect.splice(index, 1);
          this.manupuladoresarray = this.form_data.data_umbrales.manipuldoresSelect;
          break;
        case 'W':
          // this.form_data.aguasSelect.splice(index, 1);
          // this.aguasarray = this.form_data.aguasSelect;
          this.form_data.data_umbrales.aguasSelect.splice(index, 1);
          this.aguasarray = this.form_data.data_umbrales.aguasSelect;
          break;
        case 'E':
          // this.form_data.ambientesSelect.splice(index, 1);
          // this.ambientesarray = this.form_data.ambientesSelect;
          this.form_data.data_umbrales.ambientesSelect.splice(index, 1);
          this.ambientesarray = this.form_data.data_umbrales.ambientesSelect;
          break;
      }
    },
    additemSuperficie() {
      //console.log('agrega sup');
      const id = this.form_data.data_umbrales.superficiesSelect.length + 1;
      // if (this.form_data.data_umbrales.superficiesSelect.length == 3) {
      //   return false;
      // }
      this.form_data.data_umbrales.superficiesSelect.push({
        id: id,
        rsa_id: [],
        // umbral: 0,
        umbral:null,
      });
      this.superficiesarray = this.form_data.data_umbrales.superficiesSelect;
    },
    onChange(ev) {
      const list = [];
      // const selects = this.form_data.alimentosSelect;
      const selects = this.alimentosarray;
      selects.forEach((element) => {
        if(element.rsa_id) {
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }

      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
    },
    onChangesuperficie(ev) {
      const list = [];
      // const selects = this.form_data.superficiesSelect;
      const selects = this.superficiesarray;
      //console.log(selects);
      selects.forEach((element) => {
        if (element.rsa_id) {
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid);
          }
        }

      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaSuperficies.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaSuperficies = restan;
      */
    },
    additemManipuladores() {
      const id = this.form_data.data_umbrales.manipuldoresSelect.length + 1;
      this.form_data.data_umbrales.manipuldoresSelect.push({
        id,
        rsa_id: [],
        // umbral: 0,
        umbral:null,
      });
      this.manupuladoresarray = this.form_data.data_umbrales.manipuldoresSelect;
    },
    onChangemanupulador(ev) {
      const list = [];
      // const selects = this.form_data.manipuldoresSelect;
      const selects = this.manupuladoresarray;
      //console.log(selects);
      selects.forEach((element) => {
        if (element.rsa_id) {
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }
      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaManipuladores.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaManipuladores = restan;
      */
    },
    additemAguas() {
      // if (this.form_data.data_umbrales.aguasSelect.length == 2) {
      //   return false;
      // }
      const id = this.form_data.data_umbrales.aguasSelect.length + 1;
      this.form_data.data_umbrales.aguasSelect.push({
        id,
        rsa_id: [],
        // umbral: 0,
        umbral:null,
      });

      this.aguasarray = this.form_data.data_umbrales.aguasSelect;
    },
    onChangeaguas(ev) {
      const list = [];
      // const selects = this.form_data.aguasSelect;
      const selects = this.aguasarray;
      //console.log(selects);
      selects.forEach((element) => {
        if(element.rsa_id){
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }
      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaAguas.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaAguas = restan;
      */
    },
    additemAmbientes() {
      if (this.form_data.data_umbrales.ambientesSelect.length == 2) {
        return false;
      }
      const id = this.form_data.data_umbrales.ambientesSelect.length + 1;
      this.form_data.data_umbrales.ambientesSelect.push({
        id,
        rsa_id: [],
        // umbral: 0,
        umbral:null,
      });
      this.ambientesarray = this.form_data.data_umbrales.ambientesSelect;
    },
    onChangeambientes(ev) {
      const list = [];
      // const selects = this.form_data.ambientesSelect;
      const selects = this.ambientesarray;
      //console.log(selects);
      selects.forEach((element) => {
        if(element.rsa_id){
          const arrayid = element.rsa_id;
          for (let i = 0; i < arrayid.length; i++) {
            list.push(arrayid[i]);
          }
        }
      });
      // FILTRO LOS DATOS YA SELECCIONADOS
      const restan = [];
      this.data_ensayo.rsa.map((v) => {
        if (list.includes(v.id_rsa) == false) {
          restan.push(v);
        }
      });
      // this.setRSA(restan)
      /*
      const restan = [];
      this.listaAmbiente.map((v) => {
        if (list.includes(v.id) == false) {
          restan.push(v);
        }
      });
      console.log(restan);
      this.listaAmbiente = restan;
      */
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAllFruit) {
          this.selectedFruits = []
        } else {
          this.selectedFruits = this.listaAlimentos.slice()
        }
      })
    },
    labelLang(label){
      // console.log(['locale',i18n.locale,label]);
      let translate = label;
      switch (label) {
        case 'Leches Y Productos Lacteos':
          translate = (i18n.locale === "en") ? 'Dairy' : label
          break;
        case 'Helados Y Mezclas Para Helados':
          translate = (i18n.locale === "en") ? 'Ice creams and ice cream mixtures' : label
          break;
        case 'Productos Grasos':
          translate = (i18n.locale === "en") ? 'Fatty products' : label
          break;
        case 'Caldos, Sopas, Cremas Y Mezclas Deshidratadas':
          translate = (i18n.locale === "en") ? 'Broths, soups, bisques, and dehydrated mixtures' : label
          break;
        case 'Productos Elaborados A Partir De Cereales':
          translate = (i18n.locale === "en") ? 'Processed cereal products' : label
          break;
        case 'Azucares Y Miel':
          translate = (i18n.locale === "en") ? 'Sugars and honey' : label
          break;
        case 'Productos De Confiteria':
          translate = (i18n.locale === "en") ? 'Confectionery products' : label
          break;
        case 'Productos De Panaderia Y Pasteleria':
          translate = (i18n.locale === "en") ? 'Bakery products' : label
          break;
        case 'Alimentos De Uso Infantil':
          translate = (i18n.locale === "en") ? 'Infant food' : label
          break;
        case 'Carnes Y Productos Carneos':
          translate = (i18n.locale === "en") ? 'Meats and meat products' : label
          break;
        case 'Pescados Y Productos De La Pesca':
          translate = (i18n.locale === "en") ? 'Fish and fishery products' : label
          break;
        case 'Huevos Y Ovoproductos':
          translate = (i18n.locale === "en") ? 'Eggs and egg products' : label
          break;
        case 'Salsas, Aderezos, Especias Y Condimentos':
          translate = (i18n.locale === "en") ? 'Sauces, dressings, spices and condiments' : label
          break;
        case 'Frutas Y Verduras':
          translate = (i18n.locale === "en") ? 'Fruits and vegetables' : label
          break;
        case 'Comidas Y Platos Preparados':
          translate = (i18n.locale === "en") ? 'Ready meals' : label
          break;
        case 'Bebidas Y Aguas':
          translate = (i18n.locale === "en") ? 'Beverages and water' : label
          break;
        case 'Estimulantes Y Fruitivos':
          translate = (i18n.locale === "en") ? 'Stimulants and fruitives' : label
          break;
        case 'Conservas':
          translate = (i18n.locale === "en") ? 'Preserved food' : label
          break;
        case 'No Aplica':
          translate = (i18n.locale === "en") ? 'N/A' : label
          break;
        // default:
        //   translate = label
        //   break;
      }
      return translate;
    },
    //funciones limipiado de checks bloqueados
    makeid(length) {
      var result           = '';
      var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() *
          charactersLength));
      }
      return result;
    },
    cleanTreeUmbrales(arbol,umbrales){
      let idSelect = umbrales;
      arbol = JSON.parse(JSON.stringify(arbol))
      
      let umbralValue = [];
      let itemnuevo = [];
      idSelect.map((diff_umbral) =>{
        diff_umbral.umbralValue = [];
        if(!diff_umbral.hasOwnProperty('key')){
          diff_umbral.key = this.makeid(6);
        }
      });
      arbol.map(element => {

        element.isDisabled = false;
        idSelect.map((diff_umbral) =>{
          //console.log(diff_umbral,element.id);
          if(diff_umbral.rsa_id.includes(element.id)){
            element.isDisabled = true;
            diff_umbral.umbralValue.push({
              idarbol:element.id,
              label:element.label,
              customLabel:element.customLabel
            });
            umbralValue.push(
              {
                idarbol:element.id,
                label:element.label,
                customLabel:element.customLabel
              }
            )
          }
        })

        //recorremos los hijos
        if(element.hasOwnProperty('children')){
          element.children.map(element2 => {
            element2.isDisabled = false;
            idSelect.map((diff_umbral) =>{
              //console.log('lv 2',diff_umbral,element2.id);

              if (diff_umbral.rsa_id.includes(element2.id)) {
                element2.isDisabled = true;
                diff_umbral.umbralValue.push({
                  idarbol:element2.id,
                  label:element2.label,
                  customLabel:element2.customLabel
                });
                umbralValue.push(
                  {
                    idarbol: element2.id,
                    label: element2.label,
                    customLabel:element2.customLabel
                  }
                )
              }

            });

            if(element2.hasOwnProperty('children')){
              //recorremos nivel subkits (nivel3)
              element2.children.map(element3 => {
                element3.isDisabled = false;
                idSelect.map((diff_umbral) =>{
                  if(diff_umbral.rsa_id.includes(element3.id)){
                    element3.isDisabled = true;
                    diff_umbral.umbralValue.push({
                      idarbol:element3.id,
                      label:element3.label,
                      customLabel:element3.customLabel
                    });
                    umbralValue.push(
                      {
                        idarbol:element3.id,
                        label:element3.label,
                        customLabel:element3.customLabel
                      }
                    )
                  }
                });

                if(element3.hasOwnProperty('children')){
                  // recorremos nivel1 categoria nivel (4)
                  element3.children.map(element4 => {
                    element4.isDisabled = false;
                    idSelect.map((diff_umbral) =>{
                      if(diff_umbral.rsa_id.includes(element4.id)){
                        element4.isDisabled = true;
                        diff_umbral.umbralValue.push({
                          idarbol:element4.id,
                          label:element4.label,
                          customLabel:element4.customLabel
                        });
                        umbralValue.push(
                          {
                            idarbol:element4.id,
                            label:element4.label,
                            customLabel:element4.customLabel
                          }
                        )
                      }
                    });

                    if(element4.hasOwnProperty('children')){
                      //recorremos nivel2 categoria( nivel 5)
                      element4.children.map(element5 => {
                        element5.isDisabled = false;
                        idSelect.map((diff_umbral) =>{
                          if(diff_umbral.rsa_id.includes(element5.id)){
                            element5.isDisabled = true;
                            diff_umbral.umbralValue.push({
                              idarbol:element5.id,
                              label:element5.label,
                              customLabel:element5.customLabel
                            });
                            umbralValue.push(
                              {
                                idarbol:element5.id,
                                label:element5.label,
                                customLabel:element5.customLabel
                              }
                            )
                          }
                        });
                        if(element5.hasOwnProperty('children')){
                          //recoreemos nivel3 categoria (nivel 6)
                          element5.children.map(element6 => {
                            element6.isDisabled = false;
                            idSelect.map((diff_umbral) =>{
                              if(diff_umbral.rsa_id.includes(element6.id)){
                                element6.isDisabled = true;
                                diff_umbral.umbralValue.push({
                                  idarbol:element6.id,
                                  label:element6.label,
                                  customLabel:element6.customLabel
                                });
                                umbralValue.push(
                                  {
                                    idarbol:element6.id,
                                    label:element6.label,
                                    customLabel:element6.customLabel
                                  }
                                )
                              }
                            });
                            if(element6.hasOwnProperty('children')){
                              //recorremos nivel4 categoria (nivel 7)
                              element6.children.map(element7 => {
                                element7.isDisabled = false;
                                idSelect.map((diff_umbral) =>{
                                  if(diff_umbral.rsa_id.includes(element7.id)){
                                    element7.isDisabled = true;
                                    diff_umbral.umbralValue.push({
                                      idarbol:element7.id,
                                      label:element7.label,
                                      customLabel:element7.customLabel
                                    });
                                    umbralValue.push(
                                      {
                                        idarbol:element7.id,
                                        label:element7.label,
                                        customLabel:element7.customLabel
                                      }
                                    )
                                  }
                                });

                              });
                            }
                          });
                        }
                      });
                    }

                  });
                }
              });
            }
          });
        }
        itemnuevo.push(element)
      });
      //console.log('after');
      //console.log({arbol})
      //console.log({umbralValue:umbralValue,itemnuevo:itemnuevo,umbralesActivos: idSelect});
      return {umbralValue:umbralValue,itemnuevo:itemnuevo,umbralesActivos: idSelect }
    },
    bloquearUmbrales(matriz=null) {
      if(matriz ==  12 ||  matriz == null){
        if(this.alimentoItems.length){
          //console.log('entroo a bloquear alimentos');
          let infoUm = this.cleanTreeUmbrales(this.alimentoItems,this.form_data.data_umbrales.alimentosSelect)
          this.alimentoItems = infoUm.itemnuevo;
          //console.log('formdata data umbrales', this.form_data.data_umbrales.alimentosSelect)
          //console.log('despues de cleanTreeUmbrales',infoUm.umbralesActivos);
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralAlimento.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralAlimento.push({
                  umbral:umbrales.umbral,
                  unidad:umbrales.unidades,
                  items:umbrales.umbralValue,
                  key: umbrales.key,
                  parametrosubral:umbrales.parametrosubral
                })
              }
            });

          }

          //console.log('despues estos son los umbrales',this.umbralAlimento);
        }
      }
      if(matriz ==  9 ||  matriz == null){
        //console.log('manp bloquear',JSON.parse(JSON.stringify(this.form_data.data_umbrales.superficiesSelect)))
        if(this.superficieItems.length){
          let infoUm = this.cleanTreeUmbrales(this.superficieItems,this.form_data.data_umbrales.superficiesSelect)
          this.superficieItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralSuperficie.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralSuperficie.push({
                  umbral:umbrales.umbral,
                  unidad:umbrales.unidades,
                  items:umbrales.umbralValue,
                  key: umbrales.key,
                  parametrosubral:umbrales.parametrosubral
                })
              }
            });

          }
        }
        //console.log('superficies umbrales ',this.umbralSuperficie)

      }
      if(matriz ==  7 ||  matriz == null){
        if(this.aguasItems.length){
          let infoUm = this.cleanTreeUmbrales(this.aguasItems,this.form_data.data_umbrales.aguasSelect);
          this.aguasItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralAguas.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralAguas.push({
                  umbral:umbrales.umbral,
                  unidad:umbrales.unidades,
                  items:JSON.parse(JSON.stringify(umbrales.umbralValue)),
                  key: umbrales.key,
                  parametrosubral:umbrales.parametrosubral
                })
              }
            });

          }


        }
      }
      if(matriz ==  8 ||  matriz == null){
        if(this.manipuladoresItems.length){
          let infoUm = this.cleanTreeUmbrales(this.manipuladoresItems,this.form_data.data_umbrales.manipuldoresSelect)
          this.manipuladoresItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralManipuladores.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralManipuladores.push({
                  umbral:umbrales.umbral,
                  unidad:umbrales.unidades,
                  items:umbrales.umbralValue,
                  key: umbrales.key,
                  parametrosubral:umbrales.parametrosubral
                })
              }
            });

          }
        }
        //console.log('maniupuladores umbrales ',this.umbralManipuladores)
      }
      if(matriz ==  6 ||  matriz == null){
        if(this.ambientesItems.length){
          //console.log('ambientes tree',this.ambientesItem)
          let infoUm = this.cleanTreeUmbrales(this.ambientesItems,this.form_data.data_umbrales.ambientesSelect)
          this.ambientesItems = infoUm.itemnuevo;
          if(infoUm.umbralesActivos.length){
            infoUm.umbralesActivos.map((umbrales) =>{
              if(this.umbralAmbientes.findIndex((search) => search.key === umbrales.key ) === -1){
                this.umbralAmbientes.push({
                  umbral:umbrales.umbral,
                  unidad:umbrales.unidades,
                  items:umbrales.umbralValue,
                  key: umbrales.key,
                  parametrosubral:umbrales.parametrosubral
                })
              }
            });

          }
        }
        //console.log('ambientes umbrales ',this.umbralAmbientes)
      }
    },

    //añadir umbrales
    additemumbral(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.alimentoItem;
      let configUmbral = JSON.stringify(this.umbralparametroAlimento);
      //console.log( 'antes',JSON.stringify(this.form_data.data_umbrales.alimentosSelect));
      //console.log('select',JSON.stringify(this.alimentoItem));
      let umbralValue = [];
      let itemnuevo = [];
      this.alimentoItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label,
              customLabel:element.customLabel
            }
          )
        }
        //recorremos los hijos
        element.children.forEach(element2 => {
          if(idSelect.rsa_id.includes(element2.id)){
            element2.isDisabled = true;
            umbralValue.push(
              {
                idarbol:element2.id,
                label:element2.label,
                customLabel:element2.customLabel
              }
            )
          }
          //recorremos nivel subkits (nivel3)
          element2.children.forEach(element3 => {
            if(idSelect.rsa_id.includes(element3.id)){
              element3.isDisabled = true;
              umbralValue.push(
                {
                  idarbol:element3.id,
                  label:element3.label,
                  customLabel:element3.customLabel
                }
              )
            }
            // recorremos nivel1 categoria nivel (4)
            element3.children.forEach(element4 => {
              if(idSelect.rsa_id.includes(element4.id)){
                element4.isDisabled = true;
                umbralValue.push(
                  {
                    idarbol:element4.id,
                    label:element4.label,
                    customLabel:element4.customLabel
                  }
                )
              }
              //recorremos nivel2 categoria( nivel 5)
              element4.children.forEach(element5 => {
                if(idSelect.rsa_id.includes(element5.id)){
                  element5.isDisabled = true;
                  umbralValue.push(
                    {
                      idarbol:element5.id,
                      label:element5.label,
                      customLabel:element5.customLabel
                    }
                  )
                }
                //recoreemos nivel3 categoria (nivel 6)
                element5.children.forEach(element6 => {
                  if(idSelect.rsa_id.includes(element6.id)){
                    element6.isDisabled = true;
                    umbralValue.push(
                      {
                        idarbol:element6.id,
                        label:element6.label,
                        customLabel:element6.customLabel
                      }
                    )
                  }
                  //recorremos nivel4 categoria (nivel 7)
                  element6.children.forEach(element7 => {
                  if(idSelect.rsa_id.includes(element7.id)){
                    element7.isDisabled = true;
                    umbralValue.push(
                      {
                        idarbol:element7.id,
                        label:element7.label,
                        customLabel:element7.customLabel
                      }
                    )
                  }

                });
                });
              });

            });
          });
        });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {


      this.umbralAlimento.push({
        umbral:idSelect.umbral,
        unidad:idSelect.unidades,
        items:umbralValue,
        parametrosubral: JSON.parse(configUmbral)
      })
      this.alimentoItems = itemnuevo;
      //alimentamos el modelo de
      this.form_data.data_umbrales.alimentosSelect.push(
        {
          // id:idSelect.umbral,
          rsa_id:idSelect.rsa_id,
          umbral:idSelect.umbral,
          unidades:idSelect.unidades,
          key: this.makeid(6),
          parametrosubral: JSON.parse(configUmbral)
        }
      );
      }
      // limpiamos el model
      this.alimentoItem.rsa_id = [];
      // this.alimentoItem.umbral = 0;
      this.alimentoItem.umbral = null;
      this.alimentoItem.unidades = '';

      //console.log( 'despues',this.form_data.data_umbrales);

    },
    additemumbralAguas(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.aguasItem;
      let configUmbral = JSON.stringify(this.umbralparametroAgua);
      let umbralValue = [];
      let itemnuevo = [];
      this.aguasItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label,
              customLabel:element.customLabel
            }
          )
        }
        //recorremos los hijos
        element.children.forEach(element2 => {
          if(idSelect.rsa_id.includes(element2.id)){
            element2.isDisabled = true;
            umbralValue.push(
              {
                idarbol:element2.id,
                label:element2.label,
                customLabel:element2.customLabel
              }
            )
          }
          //recorremos nivel subkits (nivel3)
          element2.children.forEach(element3 => {
            if(idSelect.rsa_id.includes(element3.id)){
              element3.isDisabled = true;
              umbralValue.push(
                {
                  idarbol:element3.id,
                  label:element3.label,
                  customLabel:element3.customLabel
                }
              )
            }
            // recorremos nivel1 categoria nivel (4)
            element3.children.forEach(element4 => {
              if(idSelect.rsa_id.includes(element4.id)){
                element4.isDisabled = true;
                umbralValue.push(
                  {
                    idarbol:element4.id,
                    label:element4.label,
                    customLabel:element4.customLabel
                  }
                )
              }
              //recorremos nivel2 categoria( nivel 5)
              element4.children.forEach(element5 => {
                if(idSelect.rsa_id.includes(element5.id)){
                  element5.isDisabled = true;
                  umbralValue.push(
                    {
                      idarbol:element5.id,
                      label:element5.label,
                      customLabel:element5.customLabel
                    }
                  )
                }
                //recoreemos nivel3 categoria (nivel 6)
                element5.children.forEach(element6 => {
                  if(idSelect.rsa_id.includes(element6.id)){
                    element6.isDisabled = true;
                    umbralValue.push(
                      {
                        idarbol:element6.id,
                        label:element6.label,
                        customLabel:element6.customLabel
                      }
                    )
                  }
                  //recorremos nivel4 categoria (nivel 7)
                  element6.children.forEach(element7 => {
                    if(idSelect.rsa_id.includes(element7.id)){
                      element7.isDisabled = true;
                      umbralValue.push(
                        {
                          idarbol:element7.id,
                          label:element7.label,
                          customLabel:element7.customLabel
                        }
                      )
                    }

                  });
                });
              });

            });
          });
        });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {


        this.umbralAguas.push({
          umbral:idSelect.umbral,
          unidad:idSelect.unidades,
          items: JSON.parse(JSON.stringify(umbralValue)),
          parametrosubral: JSON.parse(configUmbral),
          key: this.makeid(6),
        })
        this.aguasItems = itemnuevo;
        //alimentamos el modelo de
        this.form_data.data_umbrales.aguasSelect.push(
          {
            // id:idSelect.umbral,
            rsa_id:idSelect.rsa_id,
            umbral:idSelect.umbral,
            unidades:idSelect.unidades,
            key: this.makeid(6),
            parametrosubral: JSON.parse(configUmbral)
            
          }
        );
      }
      // limpiamos el model
      this.aguasItem.rsa_id = [];
      // this.aguasItem.umbral = 0;
      this.aguasItem.umbral = null;
      this.aguasItem.unidades = '';

      //console.log('aguasItem',this.form_data.data_umbrales);

    },
    additemumbralSuperficie(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.superficieItem;
      let configUmbral = JSON.stringify(this.umbralparametroSuperficie);
      let umbralValue = [];
      let itemnuevo = [];
      this.superficieItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label,
              customLabel:element.customLabel
            }
          )
        }
        //recorremos los hijos
        element.children.forEach(element2 => {
          if(idSelect.rsa_id.includes(element2.id)){
            element2.isDisabled = true;
            umbralValue.push(
              {
                idarbol:element2.id,
                label:element2.label,
                customLabel:element2.customLabel
              }
            )
          }
        });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {
        this.umbralSuperficie.push({
          umbral: idSelect.umbral,
          unidad: idSelect.unidades,
          items: umbralValue,
          key: this.makeid(6),
          parametrosubral: JSON.parse(configUmbral)
        })
        this.superficieItems = itemnuevo;
        //alimentamos el modelo de
        //console.log('umbrales superficie');
        //console.log( this.form_data.data_umbrales);
        this.form_data.data_umbrales.superficiesSelect.push(
          {
            rsa_id: idSelect.rsa_id,
            umbral: idSelect.umbral,
            unidades: idSelect.unidades,
            key: this.makeid(6),
            parametrosubral: JSON.parse(configUmbral)
          }
        );
      }
      // limpiamos el model
      this.superficieItem.rsa_id = [];
      // this.superficieItem.umbral = 0;
      this.superficieItem.umbral = null;
      this.superficieItem.unidades = '';

      //console.log(this.form_data.data_umbrales);
    },
    additemumbralManipuladores(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.manipuladoresItem;
      let umbralValue = [];
      let itemnuevo = [];
      let configUmbral = JSON.stringify(this.umbralparametroManipulador);
      this.manipuladoresItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label,
              customLabel:element.customLabel
            }
          )
        }
        //recorremos los hijos
        // element.children.forEach(element2 => {
        //   if(idSelect.rsa_id.includes(element2.id)){
        //     element2.isDisabled = true;
        //     umbralValue.push(
        //       {
        //         idarbol:element2.id,
        //         label:element2.label
        //       }
        //     )
        //   }
        // });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {
        this.umbralManipuladores.push({
          umbral:idSelect.umbral,
          unidad:idSelect.unidades,
          items:umbralValue,
          key: this.makeid(6),
          parametrosubral: JSON.parse(configUmbral)
        })
        this.manipuladoresItems = itemnuevo;
        //alimentamos el modelo de
        //console.log('umbrales superficie');
        //console.log( this.form_data.data_umbrales);
        this.form_data.data_umbrales.manipuldoresSelect.push(
          {
            // id:idSelect.umbral,
            rsa_id:idSelect.rsa_id,
            umbral:idSelect.umbral,
            unidades:idSelect.unidades,
            key: this.makeid(6),
            parametrosubral: JSON.parse(configUmbral)
          }
        );
      }
      // limpiamos el model
      this.manipuladoresItem.rsa_id = [];
      // this.manipuladoresItem.umbral = 0;
      this.manipuladoresItem.umbral = null;
      this.manipuladoresItem.unidades = '';

      //console.log(this.form_data.data_umbrales);
    },
    additemumbralAmbientes(){
      //recorremos el array principal y bloqueamos los seleccionados
      let idSelect = this.ambientesItem;
      let configUmbral = JSON.stringify(this.umbralparametroAmbiente);
      let umbralValue = [];
      let itemnuevo = [];
      this.ambientesItems.forEach(element => {
        if(idSelect.rsa_id.includes(element.id)){
          element.isDisabled = true;
          //console.log('entro',element)
          umbralValue.push(
            {
              idarbol:element.id,
              label:element.label,
              customLabel:element.customLabel
            }
          )
        }
        //recorremos los hijos
        // element.children.forEach(element2 => {
        //   if(idSelect.rsa_id.includes(element2.id)){
        //     element2.isDisabled = true;
        //     umbralValue.push(
        //       {
        //         idarbol:element2.id,
        //         label:element2.label
        //       }
        //     )
        //   }
        // });
        itemnuevo.push(element)
      });
      if(idSelect.rsa_id.length > 0)
      {
        this.umbralAmbientes.push({
          umbral:idSelect.umbral,
          unidad:idSelect.unidades,
          items:umbralValue,
          key: this.makeid(6),
          parametrosubral: JSON.parse(configUmbral)
        })
        this.ambientesItems = itemnuevo;
        //alimentamos el modelo de
        //console.log('umbrales ambientes');
        //console.log( this.form_data.data_umbrales);
        this.form_data.data_umbrales.ambientesSelect.push(
          {
            // id:idSelect.umbral,
            rsa_id:idSelect.rsa_id,
            umbral:idSelect.umbral,
            unidades:idSelect.unidades,
            key: this.makeid(6),
            parametrosubral: JSON.parse(configUmbral)
          }
        );
      }
      // limpiamos el model
      this.ambientesItem.rsa_id = [];
      // this.ambientesItem.umbral = 0;
      this.ambientesItem.umbral = null;
      this.ambientesItem.unidades = '';

      //console.log(this.form_data.data_umbrales);
    },
    deleteUmbral(item,matriz_id){
      let findIndex = -1;
      let findIndexSelect = -1;
      //console.log('item key',item.key)
      switch (matriz_id) {
        case 12:
          //console.log('eliminar');
          //console.log( this.umbralAlimento)
          //console.log( this.form_data.data_umbrales.alimentosSelect)
          findIndex =  this.umbralAlimento.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralAlimento.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_umbrales.alimentosSelect.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_umbrales.alimentosSelect.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 9:
          findIndex =  this.umbralSuperficie.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralSuperficie.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_umbrales.superficiesSelect.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_umbrales.superficiesSelect.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 8:
          findIndex =  this.umbralManipuladores.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralManipuladores.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_umbrales.manipuldoresSelect.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_umbrales.manipuldoresSelect.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 7:
          findIndex =  this.umbralAguas.findIndex(elem => elem.key == item.key );
          //console.log('id control index AGUAS ',findIndex)
          if(findIndex !== -1){
            this.umbralAguas.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_umbrales.aguasSelect.findIndex(elem => elem.key == item.key );
          //console.log('id control index AGUAS umbral ',findIndexSelect)
          if(findIndexSelect !== -1){
            this.form_data.data_umbrales.aguasSelect.splice(findIndexSelect,1);

            this.bloquearUmbrales(matriz_id);
          }
          break;
        case 6:
          findIndex =  this.umbralAmbientes.findIndex(elem => elem.key == item.key );
          if(findIndex !== -1){
            this.umbralAmbientes.splice(findIndex,1);
          }
          findIndexSelect =  this.form_data.data_umbrales.ambientesSelect.findIndex(elem => elem.key == item.key );
          if(findIndexSelect !== -1){
            this.form_data.data_umbrales.ambientesSelect.splice(findIndexSelect,1);
            this.bloquearUmbrales(matriz_id);
          }
          break;
      }
    },
    nivelesSuperficie(){
      const listasup = [];
      this.data_ensayo.umbralSuperficie.forEach(element => {
        let niv_1 = element.zona.id;
        const nom = element.zona.nombre;
        const trad =  this.getlang(nom);

        element.customLabel = trad; //element.zona.nombre;
        element.id_original = element.zona.id;
        element.active = true;
        element.id = 'L_'+niv_1; //element.zona.id+'_R';
        element.label = trad; //element.zona.nombre;

        let array_children = [];
        element.zona.clasificacion_zonas.forEach(element2 => {
          let niv_2 = element2.id;
          const nom2 = element2.nombre;
          const trad2 =  this.getlang(nom2);
          element2.customLabel = trad + ' / ' + trad2;
          element2.id = 'L_'+niv_1+'_'+niv_2;//element2.id+'_S_'+element.id_original;
          element2.label = trad2

          array_children.push(element2)
        });
        element.children=array_children;
        listasup.push(element)

      });
      return listasup;
    },
    nivelesManipuladores(){
      const listaman = [];
      this.data_ensayo.umbralManipuladores.forEach(element => {

          let niv_1 = element.estado_condicion.id;
          const nom2 = element.estado_condicion.condicion;
          const trad2 =  this.getlang(nom2);

          element.customLabel = trad2;
          element.active = true;
          element.id = 'L_'+niv_1//element.estado_condicion.id+'_R';
          element.label = trad2; // element.estado_condicion.condicion;
          let array_children = [];
          element.children=array_children;
          listaman.push(element)
      });
      //console.log('manipuladores',listaman)
      return listaman;
    },
    nivelesAmbientes(){
      const listaman = [];
      //console.log('contenido ambientes',this.data_ensayo.umbralAmbiente)
      this.data_ensayo.umbralAmbiente.forEach(element => {

        let niv_1 = element.estado_condicion.id;
        const nom2 = element.estado_condicion.condicion;
        const trad2 =  this.getlang(nom2);

        element.customLabel = trad2;
        element.active = true;
        element.id = 'L_'+niv_1//element.estado_condicion.id+'_R';
        element.label = trad2; // element.estado_condicion.condicion;
        let array_children = [];
        element.children=array_children;
        listaman.push(element)
      });
      //console.log('ambiente',listaman)
      return listaman;
    },
    configumbrales(idmatriz, control = null){
      
      this.controlUmbral = control;
     
      if(this.form_data.isNew == true){
       
        ///recorremos parametros con lo que se ha seleccionado
        let parametros = [];
       
        this.data_ensayo.parametros.forEach(datos => {
          let filtroparam = this.form_data.data_ensayo.parametrosid.filter(
            (param) => param === datos.cod_pat,
          );
          if(filtroparam.length > 0){
            datos.parametro_id = datos.cod_pat;
            parametros.push(datos);
          }
        });
       
        this.form_data.paramtrosEnsayo = parametros; //this.form_data.data_ensayo.parametrosid
      }
      if(this.form_data.paramtrosEnsayo.length === 0 ){
        console.log('no hay parametros');
        return false;
      }
      // validmos si tiene un umbral seleccionado
      let umbralSeleccionado = this.validaItemSeleccionado(idmatriz);
      if(umbralSeleccionado == false){
        return false;
      }
      this.dialogconfig = true;
      this.matrizConfig = idmatriz;
      this.umbralParametro = [];
      // this.umbralParametro =  this.form_data.paramtrosEnsayo;
      console.log({umbralParametro:this.data_ensayo.parametros});
     console.log({parametroseleccionado:this.form_data.data_ensayo.parametrosid});
     this.data_ensayo.parametros.forEach(element => {
        if(this.form_data.data_ensayo.parametrosid.indexOf(element.cod_pat) != -1){
          
            // element.umbral = 0;
            element.umbral = 0;
            element.unidades = '';
            element.matriz = idmatriz;
       
          this.umbralParametro.push(element);
        }
        
       
        
       
      });
     
      console.log({UMBRALPARAM:this.umbralParametro});
      

    },
    
    guardaUmbralparametro(){
      switch (this.matrizConfig) {
        case 12:
          this.umbralparametroAlimento = this.umbralParametro;
          this.additemumbral();
          break;
        case 9:
          this.umbralparametroSuperficie = this.umbralParametro;
          this.additemumbralSuperficie();
          break;
        case 8:
          this.umbralparametroManipulador = this.umbralParametro;
          this.additemumbralManipuladores();
          break;
        case 7:
          this.umbralparametroAgua = this.umbralParametro;
          this.additemumbralAguas();
          break;
        case 6:
          this.umbralparametroAmbiente = this.umbralParametro;
          this.additemumbralAmbientes();
          break;
        case 'control':
        console.log({DATACONTROL: this.dataControl})
        console.log({umbralParametro: this.umbralParametro})
        const dataUmbralesControl = this.umbralParametro.map((param) => ({parametro_id:param.cod_pat,umbral:param.umbral, unidades:param.unidad,nombre_es:param.nombre_es}));
        this.controlUmbral.umbral = dataUmbralesControl;
       
         
      
          break;
        default:
          break;
      }
      console.log({DATACONTROLSWITCH: this.dataControl})
      this.umbralParametro = [];
      this.dialogconfig = false
    },
    editarConfigUmbral(id_matriz,control){
      console.log('UMBRAL',control)
      
    
      control.umbral.forEach(element => {
         
            let tmp = [];
            //console.log(this.form_data.data_ensayo.dataControl[this.isControl].umbral[index].umbral)
              tmp.umbral = element.umbral;
              tmp.unidades = element.unidad;
              tmp.matriz = id_matriz;
             
              this.umbralParametro.push(tmp);
             
          }); 
          this.dialogconfig = true;
       
    },
    validaItemSeleccionado(matriz){
      let status = true;
      switch (matriz) {
        case 12:
          if(this.alimentoItem.rsa_id.length == 0){
            status = false;
          }
          break;
        case 9:
          if(this.superficieItem.rsa_id.length == 0){
            status = false;
          }
          break;
        case 8:
          if(this.manipuladoresItem.rsa_id.length == 0){
            status = false;
          }
          break;
        case 7:
          if(this.aguasItem.rsa_id.length == 0){
            status = false;
          }
          break;
        case 6:
          if(this.ambientesItem.rsa_id.length == 0){
            status = false;
          }
          break;
      }
      return status;
    }
  },

};
</script>
