<!--eslint-disable -->
<style lang="scss">
.o-modal-unidades{
  border-radius:15px;

  .tabla-container {
    max-height: 90vh;
  }
  .v-data-table td {
    #app{
      padding: 16px 20px !important;
    }
  }
  .v-data-footer__select .v-select {
    margin: 8px 0 8px 34px;
  }
  .theme--light.v-data-table .v-data-footer {
    border-top: none!important;
  }
  .c-units__modal {
    border-bottom: none;
  }
}
#app .ensayos__creados .v-data-table-header th {
  font-size: 14px !important;
  // background-color: white;
  // border-bottom: 1px solid #DDDDDD !important;
  // border-top: 1px solid #DDDDDD;
}
#app .ensayos__creados.v-data-table--fixed-header .v-data-footer {
  border-width: 0;
}
</style>
<template>
<div class="o-modal-unidades">
<!--eslint-disable-->
<!--modal ver info de las unidades :hide-default-footer="true"-->
      <v-row justify="center">
        <v-dialog
          v-model="dialogInfoUnidades"
          persistent
          max-width="650px"
        >
          <v-card>
            <v-card-title>
              <span class="ml-1 fs20 bold">{{$t('ensayos.Assignunitsofmeasure')}}</span>
            </v-card-title>
            <div class="c-units__modal">
                <v-data-table
                :search="search"
                :loading="loading"
                :headers="header"
                :items="info"
                :footer-props="{itemsPerPageText: textofooter}"
                
                class="ensayos__creados"
                fixed-header dense
                >
                  <template v-slot:item.activo="{ item }">
                    <div v-if="item.activo == 0">
                      <span>
                        {{ $t('ensayos.inactivo') }}
                      </span>
                    </div>
                    <div v-else>
                      <span class="text-active">
                        {{ $t('ensayos.activo') }}
                      </span>
                    </div>
                  </template>
                </v-data-table>
            </div>

            <v-card-actions class="p-actions-groupBtn">
              <v-spacer></v-spacer>
              <v-btn
                class="mr-3 c-btn"
                color="#007AFF"
                outlined
                @click="dialogInfoUnidades = false"
              >
                {{$t('analisis.cancelar')}}
              </v-btn>
              <!--
                <v-btn
                  class="mr-3 c-btn c-btn--blue bold"
                  outlined
                  @click="guardaUmbralparametro()"
                >
                  {{$t('solicitudes.guardar')}}
                </v-btn>
              -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
      <!-- fin modal -->
</div>
</template>
<script>
/* eslint-disable */
import i18n from '../../plugins/il8n';
import Service from '../../services/apis';
import _ from 'lodash';
import { mapState, mapMutations } from 'vuex';

export default {
    data: () => ({
        search: '',
        loading: true,
        info:{},
        header: [
          { text: i18n.t('ensayos.t_param'),
            class: 'td-thparam',
            value: 'nombre',
            sortable: true,
          },
          { text: i18n.t('ensayos.thumbral'), 
            class: 'td-thumbral', 
            value: 'umbral', 
            sortable: true, 
          },
          { text: i18n.t('ensayos.thunidad'), 
            class: 'td-thunidad', 
            value: 'unidades', 
            sortable: true, 
            align: 'center',
          }
        ],
        dialogInfoUnidades: false,
    }),
    computed:{
    textofooter(){
      const textofooter = i18n.t('ensayos.Rowsperpage');
      return textofooter;
    },
    },
    methods: {
        init( info ) {
          console.log({info});
            this.info = info;
            this.dialogInfoUnidades = true;
            this.loading = false;
        },
    },
}
</script>
